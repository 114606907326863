<template>
<div class="is-fluid is-flex is-flex-direction-column is-align-items-center p-3">
    <Logo class="mb-5" />
    <div class="form-container is-rounded is-max-widescreen">
        <Search />
    </div>
</div>
</template>

<script>
import Logo from '@/assets/img/logo.svg'
import Search from './Search.vue'
export default {
    components: {
        Logo,
        Search,
    },
    data() {
        return {
        
        }
    },

  mounted() {
    if (this.$route.query.policies) {
      this.$router.push({ name: 'policy', query: { ...this.$route.query } }).catch(() => {})
    }
  }
};
</script>

<style lang="scss">
.form-container {
    width: 100%;
    max-width: 980px;
}
</style>
